import * as React from 'react'
import { Helmet } from 'react-helmet'
import { IndexLayoutProps } from './index-layout-props.model'
import Header from '../components/header/header'
import { StaticQuery, graphql } from 'gatsby'

import './index.css'
import Footer from '../components/footer/footer'

const Layout = (props: IndexLayoutProps) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          allContentfulPage {
            edges {
              node {
                title
                slug
              }
            }
          }
        }
      `}
      render={(data: any) => {
        const links = data.allContentfulPage.edges.map((e: any) => {
          return {
            title: e.node.title,
            slug: e.node.slug,
          }
        })
        return (
          <div className="layout-container">
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Sample' },
                { name: 'keywords', content: 'sample, something' },
              ]}
            />
            <Header links={links} siteTitle={data.site.siteMetadata.title} />
            <div className="layout-child">{props.children}</div>

            <Footer />
          </div>
        )
      }}
    />
  )
}

export default Layout
