import * as React from 'react';
import './footer.scss';

const Footer = () => (
  <footer>
    <div className="footer-content">
      <p>&copy; Alani Software Ltd. 2018 - {new Date().getFullYear()}</p>
    </div>
  </footer>
);

export default Footer;
