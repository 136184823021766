import * as React from 'react'
import { Link } from 'gatsby'
import { HeaderProps } from './header-props.model'
import './header.scss'
import Button from '../button/button'

const Header = (props: HeaderProps) => (
  <div className="header">
    <div className="header-items">
      <h1 className="header-title">
        <Link to="/">
          alani
          <span className="subtitle">software</span>
        </Link>
      </h1>

      <div className="spacer" />

      <div className="links">
        {props.links.map((link) => {
          return (
            <Link key={link.slug} to={'/' + link.slug}>
              {link.title}
            </Link>
          )
        })}
      </div>

      <Button title="Contact" to="/contact" />
    </div>
  </div>
)

export default Header
