import * as React from 'react';
import { Link } from 'gatsby';
import './button.scss';

interface ButtonProps {
  title: string;
  to: string;
}

const Button = (props: ButtonProps) => (
  <Link to={props.to}>
    <button className="button-style">{props.title}</button>
  </Link>
);

export default Button;